<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="操作门店" prop="companyIds">
              <operateCompanyCascader
                v-model="form.companyIds"
                :operateCompanyUrl="form.operateCompanyUrl"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="导入时间">
              <el-date-picker
                class="w100"
                v-model="form.importDates"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="批次号">
              <el-input
                v-model="form.batchNumber"
                placeholder="请输入批次号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                v-auth="714"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp" v-show="showMore">
          <template>
            <el-form-item label="操作人">
              <el-input
                v-model="form.operator"
                placeholder="请输入操作人"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="数据类型">
              <el-select
                v-model="form.dataType"
                clearable
                filterable
                placeholder="请选择数据类型"
              >
                <el-option
                  v-for="item in dataTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="结果类型">
              <el-select
                v-model="form.resultType"
                clearable
                filterable
                placeholder="请选择结果类型"
              >
                <el-option
                  v-for="item in resultTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div></div>
        <div>
          <el-button
            v-auth="649"
            size="small"
            type="text"
            class="blue"
            @click="downloadAllRecords()"
            >导出批次<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column min-width="220" label="操作门店" prop="companyName">
        </el-table-column>
        <el-table-column width="180" label="批次号" prop="batchNumber">
          <template slot-scope="scope">
            <span
              class="pointer blue"
              @click="goBatchNumberDetail(scope.row)"
              >{{ scope.row.batchNumber }}</span
            >
          </template>
        </el-table-column>
        <el-table-column min-width="160" label="数据类型" prop="dataTypeDesc">
        </el-table-column>
        <el-table-column width="100" label="导入用户" prop="operator">
        </el-table-column>
        <el-table-column width="180" label="导入时间" prop="operateDateTime">
        </el-table-column>
        <el-table-column width="110" label="批次记录数" prop="recordCount">
        </el-table-column>
        <el-table-column width="110" label="成功记录数" prop="successCount">
          <template slot-scope="scope">
            <span
              class="pointer blue"
              @click="goBatchNumberDetail(scope.row, 'success')"
              >{{ scope.row.successCount }}</span
            >
          </template>
        </el-table-column>
        <el-table-column width="110" label="失败记录数" prop="failedCount">
          <template slot-scope="scope">
            <span
              class="pointer blue"
              @click="goBatchNumberDetail(scope.row, 'failed')"
              >{{ scope.row.failedCount }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <dataImportBatchDetailPageDialog
        v-if="showBatchNumberDetailDialog"
        :visibility.sync="showBatchNumberDetailDialog"
        :batchNumber="queryBatchNumber"
        :batchType="batchType"
      ></dataImportBatchDetailPageDialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import dataImportBatchDetailPageDialog from './components/dataImportBatchDetailPageDialog.vue';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
// 接口
import { downloadFile } from '@/utils/fileApi';
import {
  dataImportRecordPreList,
  dataImportRecordList,
  dataImportRecordListExportUrl,
} from '@/api/system/dataImport';
import { hasAuth } from '@/utils/permissions';

import { mapState } from 'vuex';

export default {
  name: 'dataImportResultView',
  components: {
    pagination,
    dataImportBatchDetailPageDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      form: {},
      exportUrl: dataImportRecordListExportUrl,
      // 展示更多筛选
      showMore: true,
      listData: [],
      dataTypeList: [],
      resultTypeList: [],
      // 查询导入批次明细
      showBatchNumberDetailDialog: false,
      queryBatchNumber: '',
      batchType: '',
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.empty();
      this.getPreListData();
      this.getData();
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    /**
     * 预先加载新增/编辑的下拉框数据
     */
    getPreListData() {
      if (!hasAuth(714)) {
        return;
      }
      const _this = this;
      dataImportRecordPreList().then((res) => {
        _this.dataTypeList = res.dataTypeList;
        _this.resultTypeList = res.resultTypeList;
      });
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(714);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var preData = this.buildQueryFormData();
      var data = Object.assign({}, preData, this.pageOption);
      dataImportRecordList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    buildQueryFormData() {
      var times = {
        operateBeginTime: this.form.importDates?.[0]
          ? this.form.importDates?.[0] + ' 00:00:00'
          : '',
        operateEndTime: this.form.importDates?.[1]
          ? this.form.importDates?.[1] + ' 23:59:59'
          : '',
      };

      var data = Object.assign({}, this.form, times);
      delete data.importDates;
      return data;
    },
    downloadAllRecords() {
      var preData = this.buildQueryFormData();
      downloadFile({
        url: this.exportUrl,
        data: preData,
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        companyIds: [this.companyId],
      };
    },
    /**
     * 弹出的批次明细
     */
    goBatchNumberDetail(row, type = '') {
      if (hasAuth(716)) {
        this.queryBatchNumber = row.batchNumber;
        this.batchType = type;
        this.showBatchNumberDetailDialog = true;
      } else {
        this.$message.warning('没有权限查看批次明细');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
